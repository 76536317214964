define('cartoo-app/router', ['exports', 'ember', 'cartoo-app/config/environment'], function (exports, _ember, _cartooAppConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _cartooAppConfigEnvironment['default'].locationType,
    rootURL: _cartooAppConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('index', { "path": "/" });
    this.route('portfolio');
  });

  exports['default'] = Router;
});