define('cartoo-app/services/contact', ['exports', 'ember', 'cartoo-app/models/contact-form'], function (exports, _ember, _cartooAppModelsContactForm) {
    exports['default'] = _ember['default'].Service.extend({
        contactPerson: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('contactPerson', _cartooAppModelsContactForm['default']);
        },

        sendEmail: function sendEmail(contactPerson) {
            console.log(contactPerson);
            this.get('contactPerson').pushObject(contactPerson);
        }
    });
});