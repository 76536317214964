define('cartoo-app/controllers/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    contactService: _ember['default'].computed(function () {
      return _ember['default'].getOwner(this).lookup('service:contact');
    }),

    submitted: false,
    notSubmitted: true,

    firstName: 'Khathutshelo Shaun',
    lastName: 'Matidza',

    actions: {
      submitContactForm: function submitContactForm() {
        console.log(this.get('model.name'));
        this.set('submitted', true);
        this.set('notSubmitted', false);

        // this.get('contactService').sendEmail(data);
      }
    }
  });
});